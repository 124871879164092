<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        รายงานยอดรับชำระ
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!--      <v-btn @click="$router.push({path:'/maintenance/create'})" color="primary">-->
      <!--        <v-icon left>-->
      <!--          mdi-plus-->
      <!--        </v-icon>-->
      <!--        เพิ่มประวัติการซ่อมบำรุง-->
      <!--      </v-btn>-->
      <v-btn icon @click="showFilter = !showFilter">
        <v-icon>
          mdi-filter
        </v-icon>
      </v-btn>
    </v-toolbar>

    <div class="mr-4 box box-2" style="min-height: 100%;">
      <splitpanes style="height: 100%">
        <pane :size="showFilter ? 80 : 100" class="pl-4 pb-4">
          <v-card
              style="height: 100%;overflow: auto"
              class="pa-2 rounded-lg elevation-2"
              outlined
              tile
          >
            <v-data-table
                :headers="headers"
                :items="orders"
                :header-props="{ sortIcon: null }"
                :sort-by="['paid_date', 'customer_name']"
                :sort-desc="[false, false]"
                multi-sort
            >
              <template v-slot:item.id_display="{item}">
                <a :href="`/order/${item.id}`" target="_blank">{{ item.id_display }}</a>
              </template>
              <template v-slot:item.transfer="{item}">
<!--                <a :href="`/order/${item.id}`" target="_blank">{{ item.id_display }}</a>-->
                {{item.transfer}}
                <a v-if="item.file" :href="item.file" target="_blank">🧾</a>
                <span v-else-if="item.transfer && !item.file">ไม่มีหลักฐาน</span>
              </template>

              <template slot="body.append">
                <tr style="background-color: #eaf8ff">
                  <td>
                    รวม
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-decoration-underline text-center">{{ sum('total_price').toFixed(2) }}</td>
                  <td class="text-decoration-underline text-center">{{ sum('เงินสด').toFixed(2) }}</td>
                  <td class="text-decoration-underline text-center">{{ sum('โอนเงิน').toFixed(2) }}</td>
                  <td class="text-decoration-underline text-center">{{ sum('บัตรเครดิต').toFixed(2) }}</td>
                  <td class="text-decoration-underline text-center">{{ sum('ตัดเงินเติม').toFixed(2) }}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </pane>
        <pane :size="showFilter ? 20 : 0" class="pb-4">
          <FilterCard :search-option="findHeaders"
                      @search="getData"
                      date
                      price-min-max
                      payment-status
                      payment-method
                      order-status
                      csv
                      :json-data="csvData"
                      :labels="csvLabels"
                      file-name="รายงานยอดรับชำระ"

          >
            <template v-slot:csv>

              <!--              <v-btn class="mt-6" color="green" dark depressed>-->
              <!--                <v-icon left>mdi-table-large</v-icon>-->
              <!--                รายงาน (csv)-->
              <!--              </v-btn>-->
            </template>
          </FilterCard>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import FilterCard from "@/components/FilterCard";


export default {
  components: {Splitpanes, Pane, FilterCard},
  name: "received",
  data: () => ({
    showFilter: true,
    search: '',
    headers: [
      {
        text: 'วันที่ชำระ',
        value: 'paid_date',
      },
      {
        text: 'วันที่เปิด Order',
        value: 'order_date',
      },
      {
        text: 'ออเดอร์',
        value: 'id_display',
      },
      {
        text: 'ลูกค้า',
        value: 'customer_name',
        align: 'center'
      },
      {
        text: 'สถานะ',
        value: 'order_status',
        align: 'center'
      },
      {
        text: 'ชำระเงิน',
        value: 'payment_status',
        align: 'center'
      },
      {
        text: 'ราคารวม',
        value: 'total_price',
        align: 'center'
      },
      {
        text: 'เงินสด',
        value: 'cash',
        align: 'center'
      },
      {
        text: 'เงินโอน',
        value: 'transfer',
        align: 'center'
      },
      {
        text: 'บัตรเครดิต',
        value: 'card',
        align: 'center'
      }, {
        text: 'ตัดจากเงินเติม',
        value: 'prepaid',
        align: 'center'
      },
      {
        text: 'เครดิต',
        value: 'credit',
        align: 'center'
      },
      {
        text: 'ครบกำหนดชำระ',
        value: 'due_date',
        align: 'center'
      },
      {
        text: 'หมายเหตุ',
        value: 'remark',
      },


    ],
    orders: [],
    csvLabels: {
      order_date: {title: 'วันที่'},
      delivery_date: {title: 'วันส่ง'},
      customer_name: {title: 'ชื่อลูกค้า'},
      order_status: {title: 'สถานะ'},
      payment_status: {title: 'ชำระเงิน'},
      total_price: {title: 'ราคารวม'},
      cash: {title: 'เงินสด'},
      transfer: {title: 'เงินโอน'},
      card: {title: 'บัตรเครดิต'},
      prepaid: {title: 'ตัดจากเงินเติม'},
      credit: {title: 'เครดิต'},
      due_date: {title: 'ครบกำหนดชำระ'},
      remark: {title: 'หมายเหตุ'},
    },
    csvData: [],
    findHeaders: [
      {
        text: 'ลูกค้า',
        value: 'customer_name',
        align: 'center'
      },
    ]
  }),
  created() {
    // this.getData()
  },
  methods: {
    getData(query) {
      this.$store.state.isLoading = true
      axios.get('/api/dashboards/order_paper/' + query)
          .then(res => {
            this.orders = res.data.data
            this.orders.forEach(o => {
              if (o.payment_method === 'เงินสด') {
                o['cash'] = o.total_price
                o['transfer'] = ''
                o['card'] = ''
                o['prepaid'] = ''
              } else if (o.payment_method === 'โอนเงิน') {
                o['transfer'] = o.total_price
                o['cash'] = ''
                o['card'] = ''
                o['prepaid'] = ''
              } else if (o.payment_method === 'บัตรเครดิต') {
                o['card'] = o.total_price
                o['cash'] = ''
                o['transfer'] = ''
                o['prepaid'] = ''
              } else if (o.payment_method === 'ตัดจากเงินเติม') {
                o['prepaid'] = o.total_price
                o['cash'] = ''
                o['transfer'] = ''
                o['card'] = ''
              }
            })
            this.csvData = [...this.orders]
            this.csvData.push({
              // order_date: 'รวม',
              // total_price: this.sum('total_price')
              order_date: 'รวม',
              delivery_date: '',
              customer_name: '',
              order_status: '',
              payment_status: '',
              total_price: this.sum('total_price'),
              cash: this.sum('เงินสด'),
              transfer: this.sum('เงินโอน'),
              card: this.sum('บัตรเครดิต'),
              prepaid: this.sum('ตัดจากเงินเติม'),
              credit: '',
              due_date: '',
              remark: '',
            })
            this.csvData = JSON.parse(JSON.stringify(this.csvData).replace(/\:null/gi, "\:\"\""));
            console.log(this.csvData)
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
          .finally(() => this.$store.state.isLoading = false)
    },
    sum(type) {
      return this.orders.reduce((accum, item) => {
        if (item.payment_method === type || type === 'total_price') {
          return accum + item.total_price
        } else {
          return accum + 0
        }
      }, 0)
    }
  },
  computed: {
    // _orders(){
    //   this.orders.forEach(o=>{
    //     if(o.payment_method ==='เงินสด'){
    //
    //     }
    //   })
    // }
  }
}
</script>

<style scoped>

</style>